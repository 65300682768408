<template>
  <div class="lec-home">
    <div class="banner">
      <TopBar :type="2" :pageTitle="''" />
      <img class="banner-img" :src="lecInfo.avatar" />
    </div>
    <div class="reserve_btn" v-if="reserveState" @click="reserveBtn">预约讲师</div>
    <div class="introduce">
      <div class="brief clearfix">
        <img v-if="lecInfo.avatar" class="lec-img" :src="lecInfo.avatar" />
        <img v-else class="lec-img" src="@/assets/per_img.png" />
        <div class="lec-content">
          <div class="lec-title">
            <p>{{ lecInfo.name }}</p>
            <!-- <div class="marks" v-if="lecInfo.level">
              <div class="mark">{{ lecInfo.level }}</div>
            </div> -->
          </div>
          <p class="lec-tag">
            <span
              class="tag-text"
              v-for="(tag, index) in lecInfo.tags"
              :key="tag"
              >{{ tag + (index != lecInfo.tags.length - 1 ? "，" : "") }}</span
            >
          </p>
          <p class="lec-company">{{ lecInfo.company }}</p>
        </div>
      </div>
      <div class="intr">
        <p class="title">讲师介绍</p>
        <p :class="fold ? 'text textShow' : 'text'">{{ lecInfo.introduce }}</p>
        <p class="fold-btn" :class="{ open: fold }" @click="fold = !fold">
          {{ fold ? "收起" : "展开" }}
        </p>
      </div>
    </div>
    <div class="line"></div>
    <!-- <div class="honor-list">
      <p class="title">获得荣誉</p>
      <p class="honor" v-for="honor in lecInfo.honor" :key="honor">
        {{ honor }}
      </p>
    </div> -->
    <div class="direction-list" v-if="lecInfo.teach_direction && lecInfo.teach_direction.length>0">可定制授课方向：<span v-for="(item, index) in lecInfo.teach_direction" :key="item">{{item}}<span v-if="index+1<lecInfo.teach_direction.length">、</span></span></div>
    <div class="line"></div>
    <div class="course-list" v-if="courseList&&courseList.length>0">
      <p class="title">讲师课程</p>
      <router-link
        :to="'/recordedInfo/' + course.id"
        class="course"
        v-for="course in courseList"
        :key="course.id"
      >
        <img :src="course.image" class="course-img" />
        <div class="course-content">
          <p class="name">{{ course.name }}</p>
          <p class="duration">{{ course.duration }}课时</p>
          <p class="price"><span class="unit">¥</span>{{ course.price }}</p>
        </div>
      </router-link>
    </div>
  </div>
</template>
<script>
import TopBar from "@/m/common/topBar.vue";
export default {
  name: "LecHome",
  data() {
    return {
      lecId: "", //讲师id
      lecInfo: "", //讲师详情
      courseList: "", //讲师课程
      fold: false, //是否展开
      siteInfo:'',
      reserveState:false,
    };
  },
  components: {
    TopBar,
  },
  mounted() {
    this.lecId = this.$route.params.id;
    this.getLecInfo(); //获取详情
    this.getCourseList(); //获取课程
    this.siteInfo = JSON.parse(localStorage.getItem('siteInfo'))
    if(this.siteInfo && this.siteInfo.is_teacher_subscribe_form==1){
      this.reserveState = true
    }
  },
  methods: {
    // 跳转到预约表单
    reserveBtn(){
      this.$router.push('/company_register')
    },
    //获取详情
    getLecInfo() {
      this.$axios.post(`/v1/teacher/detail`, { id: this.lecId }).then((res) => {
        this.lecInfo = res.data;
      });
    },
    //获取课程
    getCourseList() {
      this.$axios
        .post(`/v1/teacher/courseList`, {
          id: this.lecId,
          page: this.nowPage,
          pre_page: this.limit,
        })
        .then((res) => {
          this.num = res.data.total;
          this.courseList = res.data.data;
        });
    },
    //翻页
    getNew(nowPage) {
      this.nowPage = nowPage;
      this.getCourseList();
    },
  },
};
</script>
<style scoped lang="scss">
.banner {
  width: 100%;
  height: 184px;
  overflow: hidden;
  position: relative;
  ::v-deep .top-bar {
    background: none;
    .back {
      background: url(~@/assets/arrows-white.png) center center no-repeat;
      background-size: 40px;
      transform: rotate(0deg);
    }
  }
  .banner-img {
    width: 100%;
    height: auto;
    -webkit-filter: blur(40px);
    -moz-filter: blur(40px);
    -ms-filter: blur(40px);
    -o-filter: blur(40px);
    filter: blur(40px);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }
}
.reserve_btn{
  position: absolute;
  right: 30px;
  top: 50px;
  width: 200px;
  height: 50px;
  background: #254ed4;
  color: #fff;
  text-align: center;
  line-height: 50px;
  border-radius: 5px;
  z-index: 889;
}
.introduce {
  position: relative;
  z-index: 1;
  padding: 0 28px;
  margin: -70px 0 48px;
}
.lec-img {
  width: 179px;
  height: 235px;
  border: 2px solid #fff;
  border-radius: 8px;
  float: left;
  object-fit: cover;
}
.lec-content {
  width: 480px;
  float: left;
  margin-left: 24px;
}
.lec-title {
  height: 70px;
  display: flex;
  align-items: center;
  font-size: 32px;
  color: #fff;
  .marks {
    margin: 0 0 0 20px;
    .mark {
      padding: 10px;
      background: rgba(255, 195, 11, 0.14);
      font-size: 24px;
      color: #f5a623;
    }
  }
}
.lec-tag {
  height: 76px;
  line-height: 38px;
  padding-top: 20px;
  //多行省略号
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  font-size: 26px;
  color: #666;
}
.lec-company {
  display: inline-block;
  padding: 3px 22px;
  background: rgba(37, 78, 212, 0.2);
  border-radius: 6px;
  border: 1px solid rgba(37, 78, 212, 0.2);
  font-size: 24px;
  color: #254ed4;
  margin-top: 24px;
}
.intr {
  margin-top: 48px;
  padding-bottom: 28px;
  .title {
    font-size: 32px;
    color: #333;
    margin-bottom: 30px;
  }
  .text {
    font-size: 24px;
    color: #999999;
    // line-height: 33px;
    height: 200px;
    overflow: hidden;
    &.textShow {
      height: auto;
    }
  }

  // 展开&收起
  .fold-btn {
    font-size: 24px;
    color: #999;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    &::after {
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      background: url(~@/assets/arrows-down-ico.png) right center no-repeat;
      background-size: 100% auto;
      margin-left: 10px;
    }
    &.open::after {
      transform: rotate(180deg);
    }
  }
}

.line {
  width: 100%;
  height: 10px;
  background: #f5f5f5;
}
.direction-list{
  padding: 28px;
}
.honor-list,
.course-list {
  padding: 40px 28px 10px;
  .title {
    font-size: 32px;
    color: #333;
    margin-bottom: 46px;
  }
  .honor {
    font-size: 26px;
    color: #666;
    margin-bottom: 28px;
  }
  .course {
    display: block;
    padding: 28px 0;
    overflow: hidden;
  }
  .course-img {
    width: 308px;
    height: 200px;
    border-radius: 8px;
    float: left;
  }
  .course-content {
    width: 356px;
    float: left;
    margin-left: 20px;
    .name {
      height: 80px;
      font-size: 28px;
      color: #333;
      line-height: 40px;
      //多行省略号
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    .duration {
      font-size: 24px;
      color: #999;
      margin-top: 10px;
    }
    .price {
      padding-top: 40px;
      font-size: 32px;
      color: #cd1b14;
      font-weight: bold;
      .unit {
        font-size: 24px;
      }
    }
  }
}
</style>